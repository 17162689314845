.body {
    margin: 0;
    overflow-y: inherit;
    overflow-x: hidden;
    flex-direction: row;
    display: flex;
    justify-content: center;
    height: 100vh;
}



@font-face {
    font-family: "Glitch";
    src: url('../components/Hack-Regular.ttf');
}

.App-header {
    display: flex;
    padding: 1%;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-shrink: 0;
    width: 100vw;
}

.App-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-shrink: 0;
    width: 100vw;
    height: 10vh;
}

.text {
    text-align: left;
    /* Align text to the left */
}

.logo {
    text-align: center;
    height: 100px;
    width: 125px;
    margin: 10px;
    padding: 2%;
}
.logo-twitter {
    text-align: center;
    height: 100px;
    width: 100px;
    margin: 10px;
    padding: 2%;
}

h1 {
    font-size: 1.5em;
    font-weight: bold;
    font-family: 'Glitch';
    text-transform: uppercase;
}

.paragraph {
    margin-top: 10px;
}

::-webkit-scrollbar {
    display: none;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px auto;
    width: 100%;
    max-width: 500px;
    padding: 1%;
    border-radius: 5px;
    background-color: #000;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

.name-box, .email-box {
    font-family: 'Glitch';
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    width: 100%;
    margin: 10px 0;
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    background-color: black;
}

.message-box {
    font-family: 'Glitch';
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    width: 100%;
    margin: 10px 0;
    color: white;
    background-color: black;
    height: 20px;
    max-width: 100%;
    min-width: 100%;
    max-height: 30vh;
    min-height: 10vh;
}

.send-btn {
    font-family: 'Glitch';
    flex-direction: column;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    width: 30%;
    color: white;
    background-color: black;
    display: inline-block;
    line-height: normal;
    padding: 10px 20px 10px 11%;
}