.fake-console {
    font-family: 'Courier New', monospace;
    background-color: #000;
    color: #fff;
    padding: 20px;
    overflow-y: auto;
    height: 300px;
    /* Set a fixed height or adjust as needed */
    position: relative;
}

.console {
    width: 90vw;
    height: 900px;
}

.cursor {
    position: absolute;
    bottom: 5px;
    left: 20px;
    animation: blink 1s infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}