@font-face {
    font-family: "Glitch";
    src: url('../components/Hack-Regular.ttf');
}

.App-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative;
}

.App-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #000;
    position: fixed;
    bottom: 0;
    width: 100%;
    /* Ensure the footer spans the entire width */
}

.App {
    background-color: #000;
    font-family: 'Glitch';
    flex-direction: row;
    color: white;
    overflow-y: scroll;
    overflow-x: hidden;
}

body {
    font-family: 'Glitch';
    margin: 0;
    padding: 0;
}

.image-section {
    font-family: 'Glitch';
    display: flex;
    align-items: flex-start;
    margin: 20px auto;
    min-height: 150px;
    width: 60vw;
    text-align: left;
    padding: 20px;
}

.image-section img {
    width: 150px;
    height: auto;
    margin-right: 20px;
}

.content {
    margin-left: 20px;
}


h2 {
    margin: 0;
    text-decoration: underline;
}

/* Add margin-bottom to create space between sections */
.image-section:not(:last-child) {
    margin-bottom: 20px;
}

/* if its the bottom section, add margin-bottom: 0 */
.image-section:last-child {
    margin-bottom: 0;
    padding-bottom: 10%;
}

/* Create a blinking animation for the hr */
hr {
    animation: blink 5s linear infinite;
}



@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        color: #000;
    }
    100% {
        opacity: 1;
    }
}