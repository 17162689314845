.cursor {
    display: inline-block;
    width: 0.4em;
    height: .8em;
    
    margin-left: 2px;
    background-color: #fff;
    animation: blink 1s infinite;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@keyframes blink2 {
    
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
    
}

@font-face {
    font-family: 'Glitch-2';
    src: url('Hack-Regular.ttf');
}

.sub-text {
    font-family: 'Glitch-2', sans-serif;
    color: #fff;
    font-size: 1.5rem;
    /* Set the color of the text (excluding $) */
    
}

.dollar-sign {
    color: red;
    font-size: 1.75rem;
    /* Set the color of the $ */
    animation: blink2 1s infinite;
}