.Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1%;
    justify-content: space-evenly;
    padding-top: 1%;
}

.Footer-item {
    margin: 0 10px;
    font: 14px 'Roboto', sans-serif;
    color: #fff;
    text-decoration: none;
}

.Footer-item a {
    color: white;
    text-decoration: none;
    border-left: #fff 1px solid;
    padding-left: 10px;
    border-right: #fff 1px solid;
    padding-right: 10px;
}

.Footer-item a:hover {
    color: #fff;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
}
