@font-face {
    font-family: "Glitch";
    src: url('../components/Hack-Regular.ttf');
}

.App-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative;
}

.App-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #000;
    position: fixed;
    bottom: 0;
    width: 100%;
    /* Ensure the footer spans the entire width */
}

.App {
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Glitch';
    color: white;
    overflow-y: scroll;
    overflow-x: hidden;
}

.section-l {
    display: flex;
    flex-direction: column;
    font-family: 'Glitch';
    color: white;
    width: 60vw;
    height: auto;
    text-align: left;
    padding-bottom: 5vh;
    
}