.GlitchedText {
    font-family: "Glitch";
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0;
    
}

h5 {
    font-family: "Glitch";
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 0;
}

@font-face {
    font-family: "Glitch";
    src: url('Hack-Regular.ttf');
}