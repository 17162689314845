.console{
    width: 90vw;
    height: 35vh;
    background-color: #000;
    color: #fff;
    padding: 20px;
    overflow-y: auto;
    font-family: 'Courier New', monospace;
    position: relative;
    box-shadow: #fff 0px 0px 10px;
}

.cursor{
    position: absolute;
    bottom: 5px;
    left: 20px;
    animation: blink 1s infinite;
}

.console-body {
    color: #fff;
}

