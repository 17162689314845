.notrack {
        
        display: flex;
        flex-direction: column;
        background-color: black;
        height: 100vh;
        width: 100vw;
        align-items: center;
}

h1 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0 0 1em;
    text-align: center;
    color: #fff;
    font-family: 'Glitch';
}
.header {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0 0 1em;
    text-align: center;
    color: #fff;
    font-family: 'Glitch';

}
/* Make code box in the center of the screen */
code {
    text-transform: uppercase;
    font-family: 'Glitch';
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: 75vh;
    width: 80vw;
    padding: 1em;
    background-color: #0e0e0e;
    color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
    overflow: auto;
    margin-bottom: 5%;
    text-align: center;
    line-height: 1.5em;

}

h4 {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    margin: 0 0 1em;
    color: #fff;
    font-family: 'Glitch';

}
@font-face {
    font-family: "Glitch";
    src: url('../components/Hack-Regular.ttf');
}


.App-header {
    display: flex;
    padding: 1%;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-shrink: 1;
    width: 95vw;
    height: 10vh;
    
}
.App-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-shrink: 0;
}