body {
    margin: 0;
}

.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 100vh;
    width: 100vw;
}

.main-text {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: auto;
}

.Usefullinks-body {
    text-decoration: none;
}

li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a:hover{
    color: #f00;

}

.App-header {
    display: flex;
    padding: 1%;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-shrink: 0;
}

.App-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-shrink: 0;
}