.monochrome-container {
    font-family: 'Arial', sans-serif;
    color: #fff;
    background-color: #000;
    display: flex;
    flex-direction: column;
    height: 90vh;
    align-items: center;
    /* Adjust the height as needed */
}

.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
.App-header {
    display: flex;
    padding: 1%;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-shrink: 1;
    width: 95vw;
    height: 10vh;

}
.App-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-shrink: 0;
}
input,.submit-btn {
    padding: 8px 16px;
    border: white 1px solid;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    margin-right: 20px;

}

label {
    padding-right: 10px;

}

form {
    border: white 1px solid;
    padding: 1em;
    margin-right: 20px;
    font-family: 'Glitch';
}

button {
    background-color: #000;
    color: #fff;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    font-family: 'Glitch';
}

button:hover {
    background-color: #333;
}

.results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: 'Glitch';
}

.ping,
.traceroute,
.whois {
    padding: 1em;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: #fff 1px solid;
    margin-bottom: 20px;
    font-family: 'Glitch';
    width: 90vw;
    max-width: 90vw;
}

h2 {
    margin-top: 0;
}

pre {
    background-color: #000;
    padding: 10px;
    border-radius: 4px;
    overflow: auto;
}

@font-face {
    font-family: "Glitch";
    src: url('../components/Hack-Regular.ttf');
}