.MonoNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 3rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    align-self: stretch;
}

.MonoNav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 1rem;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-shadow: #fff 1px 0 5px;
}

.MonoNav-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.25rem;
    animation: pulse 0.5s;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@font-face {
    font-family: "Glitch";
    src: url('Hack-Regular.ttf');
}

a {
    font-family: "Glitch";
    color: #fff;
    text-decoration: none;
}